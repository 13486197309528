import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { Link, navigate } from 'gatsby'
import AktaIcon from '../../../images/aktaIcon'
import CTA from '../../CTA'
import { AuthContext } from '../../../pageContext'
import '../auth.scss'

const SignUp = ({ type }) => {
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [password, setPassword] = useState('')
  const [signUpType, setSignUpType] = useState('respondent')
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [email, setEmail] = useState('')
  const { actions } = useContext(AuthContext)

  const handleSignUp = async (event) => {
    event.preventDefault()

    if (
      firstName !== '' &&
      lastName !== '' &&
      password !== '' &&
      email !== ''
    ) {
      try {
        const response = await axios.post(
          'https://akta-app-api.herokuapp.com/users/register',
          {
            firstName,
            lastName,
            password,
            email,
            userType: signUpType,
            hasCompletedProfile: false,
          }
        )
        if (response.data) {
          setIsSignedUp(true)
          actions.setIsFirstSignUp(true)
        }
      } catch (error) {
        console.log('Email Already Exists')
      }
    }
  }

  useEffect(() => {
    if (type !== undefined) {
      setSignUpType(type)
    }
  }, [type])

  return (
    <div className="loginMain">
      <div>
        <AktaIcon
          width="86.45"
          height="46"
          background="white"
          style={{
            float: 'right',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/')}
        />
      </div>
      {!isSignedUp && (
        <>
          <div>
            <h2 className="loginHeader">Create Your Account</h2>
            <p className="subHeading">
              Let’s get you started to enjoy an amazing experience.
            </p>
            {!isSignedUp && (
              <form>
                <div className="signUpContainer">
                  <label className="hideLabel" htmlFor="signUpType">
                    Sign Up Type
                  </label>
                  <select
                    className="halfInputs"
                    id="signUpType"
                    name="signUpType"
                    onChange={(event) => {
                      setSignUpType(event.target.value)
                    }}
                    value={signUpType}
                  >
                    <option value="respondent">Respondent</option>
                    <option value="researcher">Researcher</option>
                  </select>
                </div>
                <div className="leftMarginContainer">
                  <input
                    className="halfInputs"
                    placeholder="First Name"
                    type="text"
                    onChange={(event) => {
                      setfirstName(event.target.value)
                    }}
                    value={firstName}
                  />
                  <input
                    className="halfInputs"
                    placeholder="Last Name"
                    type="text"
                    onChange={(event) => {
                      setlastName(event.target.value)
                    }}
                    value={lastName}
                  />
                </div>
                <input
                  className="inputs"
                  placeholder="Email"
                  type="email"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  value={email}
                />
                <input
                  className="inputs"
                  placeholder="Password"
                  type="password"
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                  value={password}
                />
                <CTA
                  width="100%"
                  height="55px"
                  size="14px"
                  onClick={handleSignUp}
                >
                  Sign Up
                </CTA>
              </form>
            )}
          </div>
          <p className="propmt">
            Already have an acount?{' '}
            <Link className="buttonLink" to="/auth?sign-in">
              Log in
            </Link>{' '}
            here!
          </p>
        </>
      )}
      {isSignedUp && (
        <p className="propmt">
          Thank you for Signing Up! Now you can{' '}
          <Link className="buttonLink" to="/auth?sign-in">
            Log in
          </Link>{' '}
        </p>
      )}
    </div>
  )
}

export default SignUp
