import React, { useState, useContext } from 'react'
import axios from 'axios'
import { Link, navigate } from 'gatsby'
import CTA from '../../CTA'
import AktaIcon from '../../../images/aktaIcon'
import { AuthContext } from '../../../pageContext'
import '../auth.scss'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const { actions, state } = useContext(AuthContext)

  const handleUser = async (event) => {
    event.preventDefault()
    actions.setIsloading(true)
    if (password !== '' || email !== '') {
      try {
        const response = await axios.post(
          'https://akta-app-api.herokuapp.com/users/login',
          {
            email,
            password,
          },
          {
            headers: { 'Access-Control-Allow-Origin': '*' },
            withCredentials: false,
          }
        )

        if (response.data) {
          const { userId, token, user, userType, hasCompletedProfile } =
            response.data

          actions.setUserType(userType)
          actions.login(userId, token, user)
          actions.setCompletedSurveys(user.completedSurveys)
          actions.setCompletedProfile(hasCompletedProfile)
          actions.setActiveSurveys(user.activeSurveys)

          if (hasCompletedProfile) {
            navigate('/portal', { replace: true })
          } else if (!hasCompletedProfile) {
            navigate('/profile', { replace: true })
          }
        }
      } catch (error) {
        actions.setIsloading(false)
        console.log(error)
        console.log('User Does Not Exist')
      }
    } else {
      actions.setIsloading(false)
      !password ? setPasswordError(true) : setPasswordError(false)
      !email ? setEmailError(true) : setEmailError(false)
    }
    actions.setIsloading(false)
  }

  return (
    <div className="loginMain">
      <div>
        <AktaIcon
          width="86.45"
          height="46"
          background="white"
          style={{ float: 'right', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
      </div>
      <div>
        <h2 className="loginHeader">Sign In</h2>
        <p className="subHeading">
          Welcome back, discover an efficient way of creating and responding to
          surveys!
        </p>
        <form>
          {emailError && <p className="loginError">Please Enter Email</p>}
          <input
            className="inputs"
            placeholder="Email"
            type="email"
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            value={email}
          />
          {passwordError && <p className="loginError">Please Enter Password</p>}
          <input
            className="inputs"
            placeholder="Password"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
          <p className="forgot">
            <Link className="buttonLink" to="/auth?reset">
              Forgot Password?
            </Link>
          </p>
          <CTA width="100%" height="55px" size="14px" onClick={handleUser}>
            Login
          </CTA>
        </form>
      </div>
      <div>
        <p className="propmt">
          Don't have an account?{' '}
          <Link className="buttonLink" to="/auth?sign-up">
            Register
          </Link>
        </p>
      </div>
    </div>
  )
}

export default SignIn
