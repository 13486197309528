import React, { useState, useEffect } from 'react'
import SignUp from '../../components/Auth/SignUp'
import SignIn from '../../components/Auth/SignIn'
import ResetPassword from '../../components/Auth/Reset'
import './authLayout.scss'

const Auth = ({ location }) => {
  const [param, setParam] = useState(undefined)
  const [type, setType] = useState(undefined)
  useEffect(() => {
    const params = location.search.split('?').filter((p) => p !== '')
    setParam(params[0])
    setType(params[1])
  }, [location])
  return (
    <div style={{ zIndex: '50' }}>
      <div className="leftPanel" />
      <div className="rightPanel">
        <div className="authContainer">
          {param && param === 'sign-in' && <SignIn />}
          {param && param === 'sign-up' && <SignUp type={type} />}
          {param && param === 'reset' && <ResetPassword />}
        </div>
      </div>
    </div>
  )
}

export default Auth
