import React, { useState, useContext } from 'react'
import axios from 'axios'
import { Link, navigate } from 'gatsby'
import { AuthContext } from '../../../pageContext'
import '../auth.scss'
import CTA from '../../CTA'
import AktaIcon from '../../../images/aktaIcon'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(undefined)
  const [isReset, setIsReset] = useState(false)
  const { actions } = useContext(AuthContext)

  const handleReset = async (event) => {
    event.preventDefault()
    if (email !== '') {
      try {
        const response = await axios.post(
          'https://akta-app-api.herokuapp.com/users/reset-password',
          { email }
        )
        console.log(response)
        setIsReset(true)
      } catch (error) {
        setEmailError(error.response.data.msg)
      }
    }
  }

  return (
    <div className="loginMain">
      <div>
        <AktaIcon
          width="86.45"
          height="46"
          background="white"
          style={{ float: 'right', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
      </div>
      <div>
        <h2 className="loginHeader">Reset Password</h2>
        {emailError && <p className="loginError">{emailError}</p>}
        {!isReset && (
          <form>
            <input
              className="inputs"
              placeholder="Enter Email"
              type="email"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              value={email}
            />
            <CTA width="100%" height="45px" size="14px" onClick={handleReset}>
              Reset
            </CTA>
          </form>
        )}
        {isReset && (
          <p className="propmt">
            Thank you for Reseting Your password! Now you can{' '}
            <Link className="buttonLink" to="/auth?sign-in">
              Log in
            </Link>
            !
          </p>
        )}
      </div>
      {!isReset && (
        <p className="propmt">
          Remembered Your Password?{' '}
          <Link className="buttonLink" to="/auth?sign-in">
            Log in
          </Link>
        </p>
      )}
    </div>
  )
}

export default ResetPassword
